var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"page-static"},[_c('section',{staticClass:"static-content container"},[_c('div',{staticClass:"static-content__inner container-inner"},[_c('h1',{staticClass:"static-content__title"},[_vm._v(_vm._s(_vm.$t("Оцініть нас")))]),(_vm.loadedQuestions)?_c('div',{staticClass:"feedback"},[_vm._l((_vm.loadedQuestions),function(option){return _c('div',{key:option.id,staticClass:"feedback__option"},[[_c('p',[_vm._v(" "+_vm._s(option.value ?? option.question)+" ")]),_c('div',{staticClass:"feedback__stars"},_vm._l((_vm.rateOptions),function(rate){return _c('div',{key:rate.question_id,staticClass:"feedback__rate",attrs:{"id":rate.question_id},on:{"click":function($event){_vm.$route.query.edit
                    ? _vm.handleRateSelect(option.question_id, rate.question_id)
                    : _vm.handleRateSelect(option.id, rate.question_id)}}},[_c('BaseSvg',{staticClass:"feedback__icon",class:{
                    _active: _vm.$route.query.edit
                      ? _vm.checkIsActiveStar(option.question_id, rate.question_id)
                      : _vm.checkIsActiveStar(option.id, rate.question_id),
                  },attrs:{"name":"ico-star"}}),_c('div',[_vm._v(_vm._s(rate.title))])],1)}),0)]],2)}),_c('div',{staticClass:"feedback__comment"},[_c('BaseInput',{key:"comment",staticClass:"form-basket__input _full-width",attrs:{"label":_vm.$t('Коментар'),"textarea":"","value":_vm.inputs.feedback_comment.value,"row":"6"},on:{"input":function($event){return _vm.setInput(['feedback_comment', $event])}}})],1),_c('div',{staticClass:"feedback__btn"},[_c('button',{staticClass:"base-btn",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.sendForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Надіслати відгук"))+" "),(_vm.pendingSend)?_c('BasePreloader'):_vm._e()],1),(_vm.showEmptyFormError)?_c('p',{staticClass:"feedback__form_error"},[_vm._v("Оцініть принаймні один з сервісів")]):_vm._e()])],2):_c('div',[_vm._v("ELSE")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }