<template>
  <main class="page-static">
    <section class="static-content container">
      <div class="static-content__inner container-inner">
        <h1 class="static-content__title">{{ $t("Оцініть нас") }}</h1>
        <div v-if="loadedQuestions" class="feedback">
          <div :key="option.id" class="feedback__option" v-for="option of loadedQuestions">
            <template>
              <p>
                {{ option.value ?? option.question }}
              </p>

              <div class="feedback__stars">
                <div
                  @click="
                    $route.query.edit
                      ? handleRateSelect(option.question_id, rate.question_id)
                      : handleRateSelect(option.id, rate.question_id)
                  "
                  :key="rate.question_id"
                  class="feedback__rate"
                  :id="rate.question_id"
                  v-for="rate of rateOptions"
                >
                  <BaseSvg
                    name="ico-star"
                    class="feedback__icon"
                    :class="{
                      _active: $route.query.edit
                        ? checkIsActiveStar(option.question_id, rate.question_id)
                        : checkIsActiveStar(option.id, rate.question_id),
                    }"
                  />

                  <div>{{ rate.title }}</div>
                </div>
              </div>
            </template>
          </div>

          <div class="feedback__comment">
            <BaseInput
              key="comment"
              :label="$t('Коментар')"
              textarea
              :value="inputs.feedback_comment.value"
              @input="setInput(['feedback_comment', $event])"
              row="6"
              class="form-basket__input _full-width"
            />
          </div>

          <div class="feedback__btn">
            <button type="button" class="base-btn" @click.prevent="sendForm">
              {{ $t("Надіслати відгук") }}

              <BasePreloader v-if="pendingSend" />
            </button>

            <p class="feedback__form_error" v-if="showEmptyFormError">Оцініть принаймні один з сервісів</p>
          </div>
        </div>

        <div v-else>ELSE</div>
      </div>
    </section>
  </main>
</template>

<script>
import { http } from "@/axios";

export default {
  name: "Feedback",
  data() {
    return {
      inputs: {
        feedback_comment: {
          value: "",
        },
      },
      loadedQuestions: null,
      rateOptions: [
        { question_id: 1, title: this.$t("Погано") },
        { question_id: 2, title: this.$t("Так собі") },
        { question_id: 3, title: this.$t("Нормально") },
        { question_id: 4, title: this.$t("Добре") },
        { question_id: 5, title: this.$t("Чудово") },
      ],
      pendingSend: false,
      showEmptyFormError: false,
    };
  },
  computed: {},
  methods: {
    load() {
      if (this.$route.query.edit) {
        this.loadedQuestions = JSON.parse(window.localStorage.getItem("oldReviews"));
        this.inputs.feedback_comment.value = JSON.parse(window.localStorage.getItem("oldComment"));

        console.log(this.loadedQuestions, "loaded");
      } else {
        http
          .get(`/api/questions`)
          .then(({ data }) => {
            this.loadedQuestions = data.data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    checkIsFormFilled() {
      return this.loadedQuestions.some((item) => item.rate);
    },
    sendForm() {
      if (!this.checkIsFormFilled()) {
        this.showEmptyFormError = true;
        return;
      } else {
        this.pendingSend = true;

        const feedBackFormData = {
          rates: [],
          comment: this.inputs.feedback_comment.value,
          order_id: this.$route.query.order_id,
        };

        feedBackFormData.rates = this.loadedQuestions
          .map((item) => ({ id: item.id ?? item.question_id, rate: item.rate }))
          .filter((item) => item.rate);

        http
          .post("/api/order-review", feedBackFormData)
          .then(() => {
            this.$vModal.open("message", {
              title: this.$t("Дякуємо за відгук!"),
            });

            this.$router.push("/");
          })
          .catch((error) => {
            this.$vModal.open("message", {
              message: this.$t("Виникла помилка. Спробуйте, будь ласка, ще раз."),
            });
            this.handlerErrorMix(error, this.inputs);
          })
          .finally(() => {
            this.pendingSend = false;
          });
      }
    },

    setInput([key, value]) {
      this.inputs[key].value = value;
    },

    handleRateSelect(optionId, rateId) {
      this.showEmptyFormError = false;
      if (this.$route?.query?.edit) {
        const foundIndex = this.loadedQuestions.findIndex(
          (item) => item.question_id.toString() === optionId.toString()
        );

        this.$set(this.loadedQuestions[foundIndex], "rate", rateId);
      } else {
        const foundIndex = this.loadedQuestions.findIndex((item) => item.id.toString() === optionId.toString());

        this.$set(this.loadedQuestions[foundIndex], "rate", rateId);
      }
    },

    checkIsActiveStar(option, rate) {
      if (this.$route?.query?.edit) {
        return this.loadedQuestions.some((item) => item.question_id === option && item.rate >= rate);
      } else {
        return this.loadedQuestions.some((item) => item.id === option && item.rate >= rate);
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped lang="less">
@import "~@/assets/less/vars/vars.less";

.feedback {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 50rem);
  grid-gap: 3rem 2rem;

  @media (max-width: @md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: @sm) {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  &__form_error {
    color: @color-red;
  }

  &__option {
    color: @turquoise;
  }

  &__stars {
    max-width: 35rem;
    display: grid;
    grid-template-columns: repeat(5, 20%);
  }

  &__rate {
    text-align: center;

    font-size: 1.2rem;
    color: #000000;
    cursor: pointer;

    transition: color 0.3s;

    &:hover {
      color: @turquoise;
    }
  }

  &__btn {
    width: 250px;
  }

  &__icon {
    margin: 0 auto;
    width: 2rem;
    height: 2rem;
    color: #e5e5e5;

    &._active {
      color: #ffc107;
    }
  }

  &__comment {
    width: 35rem;
  }
}
</style>
